// eslint-disable-next-line
import base from './baseApi';

const { urls, methods, execute } = base();
const uploadFile = (payload: {file?: any }) => {
  const {  file } = payload;
  const method = methods.POST;
  const url = `${urls.files.files}`;
  const response = execute(method, url, file);
  return response;
};

const copyFile = (payload: any) => {
  const method = methods.POST;
  const url = `${urls.files.copyFile}`;
  const response = execute(method, url, payload);
  return response;
};

export {
  uploadFile,
  copyFile
};
