import base from './baseApi';
import { queryFilter } from 'utils/proptypes';
import { createSearchParams } from 'react-router-dom';
import { omitBy } from 'lodash';

const { urls, methods, execute } = base();

const getRoles = (queryFilter: queryFilter) => {
  const initParams:any = omitBy(queryFilter, v => v === '')
  const params = createSearchParams(initParams);
  const method = methods.GET;
  let url = `${urls.role.Roles}?${params}`;
  const response = execute(method, url);
  return response;
};

const getPermissions = () => {
  const method = methods.GET;
  const url = `${urls.role.Roles}/permissions`;
  const response = execute(method, url);

  return response;
};

const updateRole = (id: any, payload: any) => {
  const method = methods.PUT;
  const url = `${urls.role.Roles}/${id}`;
  const response = execute(method, url, payload);

  return response;
};

const createRole = (payload: any) => {
  const method = methods.POST;
  const url = urls.role.Roles;
  const response = execute(method, url, payload);

  return response;
};

const deleteRole = (id: any) => {
  const method = methods.DELETE;
  const url = `${urls.role.Roles}/${id}`;
  const response = execute(method, url);

  return response;
};

const getOrgRoles = () => {
  const method = methods.GET;
  const url = `${urls.role.organizationRoles}`;
  return execute(method, url);
};
export { getRoles, getPermissions, updateRole, createRole, deleteRole, getOrgRoles };
