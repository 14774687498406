/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Svg from 'components/svg';
import InputText from 'components/inputText';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from 'components/button/button';
import { routePaths } from 'utils/constants';
import { fill, omit, size } from 'lodash';
import { useMutation } from 'react-query';
import { checkVerificationCode, generateVerificationCode, resetPassword } from 'api/authenApi';
import { toast } from 'react-toastify';
import { Spinner } from 'flowbite-react';
import { verifyPassword } from 'utils/utils';

const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const [t] = useTranslation();
  const inputRef: any = useRef(null);
  const [step, setStep] = useState('forgot');
  const [secondsLeft, setSecondsLeft] = useState(60);
  const [emailAddress, setEmailAddress] = useState();
  const [arrayPasscode, setArrayPasscode]: any = useState(fill(Array(6), null));
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [token, setToken] = useState('');

  useEffect(() => {
    const interval = setInterval(() => step === 'verify' && setSecondsLeft(s => s - 1), 1000);
    return () => {
      clearInterval(interval);
    };
  });

  const hookForm = useForm({ mode: 'onChange' });
  const errors = hookForm.formState.errors;

  const muGenerateVerificationCode = useMutation('generateVerificationCode', {
    mutationFn: generateVerificationCode,
    onSuccess: (response: any, variables: any) => {
      setStep('verify');
      setEmailAddress(variables.emailAddress);
      setArrayPasscode(fill(Array(6), null));
      setSecondsLeft(60);
      setTimeout(() => checkFocus(), 500);
    },
    onError: () => {
      toast.error(`${t('emailNotRegistered')}`);
    },
  });

  const mucheckVerificationCode = useMutation('checkVerificationCode', {
    mutationFn: checkVerificationCode,
    onSuccess: (response: any) => {
      navigate(routePaths.CHANGE_PASSWORD + `?token=${response?.data?.token}`)
    },
    onError: () => {
      toast.error(`${t('verificationCodeNotInCorrect')}`);
    },
  });

  const muResetPassword = useMutation('resetPassword', {
    mutationFn: resetPassword,
    onSuccess: () => {
      setStep('finish');
      toast.success(`${t('createPassSuccess')}`);
    },
    onError: () => {
      toast.error(`${t('createPassFailed')}`);
    },
  });

  const onSubmit = (value: any) => {
    if (step === 'forgot') {
      muGenerateVerificationCode.mutate(value);
    } else {
      muResetPassword.mutate({ token, ...omit(value, 'emailAddress') });
    }
  };

  const handleVerification = (event: any) => {
    const value = event.currentTarget.value;
    if (mucheckVerificationCode.isLoading) {
      return;
    } else if (size(value) <= 6) {
      const splitValue = value.split('');
      const newArray = fill(Array(6), null).map((elm, index) => splitValue[index] || elm);
      setArrayPasscode(newArray);
      if (size(value) === 6) {
        mucheckVerificationCode.mutate({ emailAddress, verificationCode: value });
      }
    }
  };
  const svgEye = (name: any, show: any) => (
    <Svg className={errors?.[name]?.message ? `text-red-600` : 'text-gray-500'} name={show ? 'eye-slash' : 'eye'} />
  );
  const checkFocus = () => {
    if (inputRef?.current) {
      inputRef.current.blur();
      inputRef.current.focus();
    }
  };

  return (
    <div className="flex flex-1 bg-tertiaryDark h-screen items-center justify-center" onClick={checkFocus}>
      <div className="w-[440px] px-12 pt-11 pb-6 bg-white rounded-md flex flex-col items-center shadow-lg">
        {step === 'forgot' && (
          <div className="flex flex-col items-center w-full">
            <Svg name="logo-text" />
            <p className="py-7 text-2xl font-medium">{t('ForgotPassword')}</p>
            <p className="pb-7 text-base text-gray-500 text-center">{t('ForgotPasswordNote')}</p>
            <div className="w-full">
              <InputText
                hookForm={hookForm}
                rules={{
                  required: t('userManagementPage.requiredField'),
                  pattern: { value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: t('invalidEmail') },
                }}
                focus
                disabled={muGenerateVerificationCode.isLoading}
                placeholder={`${t('EmailAddress')}*`}
                name="emailAddress"
                isHolderLabel={true}
                rightIcon={<Svg className={errors?.emailAddress?.message ? `text-red-600` : 'text-gray-500'} name="email" />}
              />
              <Button
                color="primary"
                className="font-semibold w-full flex items-center justify-center py-2 mt-8"
                disabled={muGenerateVerificationCode.isLoading}
                onClick={hookForm.handleSubmit(onSubmit)}
              >
                <span className="text-base">{t('Continue')}</span>
                {muGenerateVerificationCode.isLoading && <Spinner size="sm" className="ml-2" />}
              </Button>
              <div
                className="w-full py-4 flex items-center justify-center mt-2 cursor-pointer"
                onClick={() => !muGenerateVerificationCode.isLoading && navigate(routePaths.LOGIN_PAGE)}
              >
                <span className="text-base font-bold text-primary">{t('Backto')}</span>
              </div>
            </div>
          </div>
        )}
        {step === 'verify' && (
          <div className="flex flex-col items-center w-full">
            <input ref={inputRef} className="opacity-0 absolute" type="number" onChange={handleVerification} />
            <p className="text-base text-center">{t('confirmPassNote1')}</p>
            <p className="text-base text-center font-semibold">{emailAddress}</p>
            <div className="flex flex-row my-8 items-center">
              {arrayPasscode.map((elm: any, index: any) => (
                <>
                  <div className="w-[40px] h-[50px] rounded-[10px] bg-lightTertiary flex items-center justify-center mx-1.5" key={`key-${index + 1}`}>
                    {elm !== null && <span className="text-black font-bold text-[36px]">{elm}</span>}
                  </div>
                  {index === 2 && <div key={`key-l-${index + 1}`} className="w-[8px] h-[3.5px] bg-black mx-1" />}
                </>
              ))}
            </div>
            {secondsLeft < 1 && (
              <button
                disabled={muGenerateVerificationCode.isLoading}
                className="text-base text-center text-tertiary font-semibold"
                onClick={() => !muGenerateVerificationCode.isLoading && muGenerateVerificationCode.mutate({ emailAddress })}
              >
                {t('confirmPassNote2')}
              </button>
            )}
            {secondsLeft > 0 && <p className="text-base text-center">{t('confirmPassNote2')}</p>}
            {secondsLeft > 0 && (
              <p className="text-base text-center text-tertiary font-semibold">
                {t('confirmPassNote3')} {secondsLeft}
              </p>
            )}
            <div className="w-full py-4 flex items-center justify-center mt-2 cursor-pointer" onClick={() => setStep('forgot')}>
              <span className="text-base font-bold text-primary">{t('Backto')}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
