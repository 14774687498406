import { queryFilter } from 'utils/proptypes';
import base from './baseApi';
import { createSearchParams } from 'react-router-dom';
import { omitBy } from 'lodash';

const { urls, methods, execute } = base();
const getSiteUsers = async (queryFilter: queryFilter) => {
  const method = methods.GET;
  const initParams: any = omitBy(queryFilter, v => v === '')
  const params = createSearchParams(initParams);

  const url = `${urls.user.siteUsers}?${params.toString()}`;
  const response = await execute(method, url);
  return response;
};

const getOrganizationUsers = async (queryFilter: queryFilter) => {
  const initParams: any = omitBy(queryFilter, v => v === '')
  const params = createSearchParams(initParams);
  const method = methods.GET;
  const url = `${urls.user.organizationUsers}?${params.toString()}`;
  const response = await execute(method, url);
  return response;
};

const getCurrentUser = (idToken = '') => {
  const method = methods.GET;
  const url = `${urls.user.getCurrentUser}${idToken ? `?idToken=${idToken}` : ''}`;
  const response = execute(method, url);

  return response;
};

const updateUserProfile = (payload: any) => {
  const method = methods.PUT;
  const url = `${urls.user.userProfile}`;
  const response = execute(method, url, payload);

  return response;
};

const updateSiteUser = ({ id, payload }: any) => {
  const method = methods.PUT;
  const url = `${urls.user.siteUsers}/${id}`;
  const response = execute(method, url, payload);

  return response;
};

const updateOrganizationUser = ({ id, payload }: any) => {
  const method = methods.PUT;
  const url = `${urls.user.organizationUsers}/${id}`;
  const response = execute(method, url, payload);

  return response;
};

const createSiteUser = ({ payload }: any) => {
  const method = methods.POST;
  const url = urls.user.siteUsers;
  const response = execute(method, url, payload);
  return response;
};

const createOrganizationUser = ({ payload }: any) => {
  const method = methods.POST;
  const url = urls.user.organizationUsers;
  const response = execute(method, url, payload);
  return response;
};

const changeEmailAddress = (id: string, emailAddress: string) => {
  const method = methods.GET;
  const url = `${urls.user.setRoles}/?id=${id}&emailAddress=${emailAddress}`;
  const response = execute(method, url);

  return response;
};

const updateActiveUserById = (id: string, payload: { isActive: boolean }) => {
  const method = methods.PATCH;
  const url = `${urls.user.users}/${id}`;
  const response = execute(method, url, payload);

  return response;
};

const setRolesForUser = (payload: { userId: string, roleIds: string[] }) => {
  const method = methods.GET;
  const url = urls.user.setRoles;
  const response = execute(method, url, payload);
  return response;
};

const updateUserImage = (payload: { id: string, file?: any }) => {
  const { id, file } = payload;
  const method = methods.POST;
  const url = `${urls.user.users}/${id}/uploadimage`;
  const response = execute(method, url, file);
  return response;
};

const resetPassword = async (payload: any) => {
  const method = methods.PATCH;
  const url = urls.user.resetPassword;
  const response = await execute(method, url, payload);
  return response;
};

const getSubscriptionsByUserId = async (userId: string, queryFilter?: queryFilter) => {
  const method = methods.GET;
  const url = `${urls.user.users}/${userId}/subscriptions`;
  const response = await execute(method, url, queryFilter);
  return response;
};

const getUsersByEmail = async (emailAddress: string) => {
  const method = methods.GET;
  const url = `${urls.user.userEmail}?emailAddress=${encodeURIComponent(emailAddress)}`;
  const response = await execute(method, url);
  return response;
};

const getUserById = async (id: string) => {
  const method = methods.GET;
  const url = `${urls.user.users}/${id}`;
  const response = await execute(method, url);
  return response;
};

const removeUser = async (id: string) => {
  const method = methods.DELETE;
  const url = `${urls.user.organizationUsers}/${id}`;
  const response = await execute(method, url);
  return response;
};

const assignRoles = ({ id, payload }: any) => {
  const method = methods.PUT;
  const url = `${urls.user.users}/${id}/roles`;
  return execute(method, url, payload);
};

const deleteOrganizationUser = async (id: string) => {
  const method = methods.DELETE;
  const url = `${urls.user.organizationUsers}/${id}`;
  const response = await execute(method, url);
  return response;
};

const assignPatient = async (payload: any) => {
  const method = methods.PUT;
  const url = `${urls.user.organizationUsers}/assignPatient`;
  const response = await execute(method, url, payload);
  return response;
};

const assignTherapist = async (payload: any) => {
  const method = methods.PUT;
  const url = `${urls.user.organizationUsers}/assignTherapist`;
  const response = await execute(method, url, payload);
  return response;
};

const unassignPatient = async (payload: any) => {
  const method = methods.PUT;
  const url = `${urls.user.organizationUsers}/unassignPatient`;
  const response = await execute(method, url, payload);
  return response;
};

export {
  assignPatient,
  assignTherapist,
  assignRoles,
  changeEmailAddress,
  createOrganizationUser,
  createSiteUser,
  deleteOrganizationUser,
  getCurrentUser,
  getOrganizationUsers,
  getSiteUsers,
  getSubscriptionsByUserId,
  getUserById,
  getUsersByEmail,
  removeUser,
  resetPassword,
  setRolesForUser,
  unassignPatient,
  updateActiveUserById,
  updateOrganizationUser,
  updateSiteUser,
  updateUserImage,
  updateUserProfile,
};
