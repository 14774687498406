import { useLocation, useNavigate } from 'react-router-dom';
import Svg from 'components/svg';
import InputText from 'components/inputText';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from 'components/button/button';
import { DEVICE_ID, routePaths } from 'utils/constants';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { signup } from 'api/authenApi';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { Spinner } from 'flowbite-react';
import { verifyPassword } from 'utils/utils';
import { setToken } from 'oauth';
import useUser from 'hooks/useUser';
import { map, split } from 'lodash';

const SignupPage = () => {
  const navigate = useNavigate();
  const [t] = useTranslation();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const { fetchCurrentUser } = useUser();
  const searchParams = Object.fromEntries(map(split(location.search.substring(1), '&'), elm => split(elm, '=')));
  const email = searchParams?.email;
  const hookForm = useForm({ mode: 'onChange', defaultValues: { email: email || '', password: '', confirmPassword: '' } });
  const errors: any = hookForm.formState.errors;

  const muSignup = useMutation('signup', {
    mutationFn: signup,
    onSuccess: async data => {
      toast.success(`${t('registrationSuccessful')}`);
      setToken(data?.data, 3600 * 24 * 30);
      await fetchCurrentUser(data.id_token);
      navigate(routePaths.DASHBOARD_PAGE);
    },
    onError: (error: any) => {
      if (error?.response?.data?.errors?.[0]?.detail) {
        toast.error(`${error.response?.data?.errors?.[0]?.detail}`);
      }
    },
  });

  const onSubmit = async ({ password, email }: any) => {
    let deviceId = localStorage.getItem(DEVICE_ID);
    if (!deviceId) {
      deviceId = uuidv4();
      localStorage.setItem(DEVICE_ID, deviceId);
    }
    muSignup.mutate({ password, deviceId, traits: { email } });
  };

  const svgEye = (name: any, show: any) => (
    <Svg className={errors?.[name]?.message ? `text-red-600` : 'text-gray-500'} name={show ? 'eye-slash' : 'eye'} />
  );

  return (
    <div className="flex flex-1 bg-tertiaryDark h-screen items-center justify-center">
      <div className="w-[500px] px-12 py-11 bg-white rounded-md flex flex-col items-center">
        <Svg name="logo-text" />
        <p className="py-7 text-2xl font-medium">{t('RegisterAccount')}</p>
        <div className="w-full">
          <InputText
            hookForm={hookForm}
            rules={{
              required: t('userManagementPage.requiredField'),
              pattern: { value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: t('invalidEmail') },
            }}
            placeholder={`${t('EmailAddress')}*`}
            name="email"
            disabled={!!email}
            focus
            isHolderLabel
            rightIcon={<Svg className={errors?.email?.message ? `text-red-600` : 'text-gray-500'} name="email" />}
          />
          <InputText
            hookForm={hookForm}
            rules={{
              required: t('passwordRequired'),
              minLength: { value: 8, message: t('min8Char') },
              validate: (value: any) => verifyPassword(value, t),
            }}
            placeholder={`${t('Password')}*`}
            name="password"
            type={showPassword ? 'text' : 'password'}
            focus
            isHolderLabel
            className="mt-4"
            handleClick={() => setShowPassword(!showPassword)}
            rightIcon={svgEye('password', showPassword)}
          />
          <InputText
            hookForm={hookForm}
            rules={{
              required: t('confirmPasswordRequired'),
              validate: (value: any) => value === hookForm.getValues('password') || t('passwordsDontMatch'),
            }}
            placeholder={`${t('ConfirmPassword')}*`}
            name="confirmPassword"
            type={showPasswordConfirm ? 'text' : 'password'}
            focus
            isHolderLabel
            className="mt-4"
            handleClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
            rightIcon={svgEye('confirmPassword', showPasswordConfirm)}
          />
          <Button
            color="primary"
            className="font-semibold w-full flex items-center justify-center py-2 mt-8"
            disabled={muSignup.isLoading}
            onClick={hookForm.handleSubmit(onSubmit)}
          >
            <span className="text-base">{t('SignUp')}</span>
            {muSignup.isLoading && <Spinner size="sm" className="ml-2" />}
          </Button>
          <div className="flex justify-center text-sm pt-2">
            <span>{t('AlreadyAccount')}</span>
            <span className="text-primary cursor-pointer pl-1 font-semibold" onClick={() => navigate(routePaths.LOGIN_PAGE)}>
              {t('SignIn')}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupPage;
