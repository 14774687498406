import { LoginPage, SignupPage } from 'pages';
import ChangePasswordPage from 'pages/authen/ChangePassword';
import ForgotPasswordPage from 'pages/authen/forgotPassword';
import { Navigate, Route, Routes } from 'react-router-dom';
import { routePaths } from 'utils/constants';
const { LOGIN_PAGE, SIGNUP_PAGE, FORGOT_PASSWORD, CHANGE_PASSWORD } = routePaths;

const NotAuthenRouters = () => {
  return (
    <Routes>
      <Route path={LOGIN_PAGE} element={<LoginPage />} key={`route-key-login`} />
      <Route path={SIGNUP_PAGE} element={<SignupPage />} key={`route-key-signup`} />
      <Route path={FORGOT_PASSWORD} element={<ForgotPasswordPage />} key={`route-key-forgot-password`} />
      <Route path={CHANGE_PASSWORD} element={<ChangePasswordPage />} key={`route-key-change-password`} />
      <Route path="*" element={<Navigate to={LOGIN_PAGE} />} key={`route-key-not-found`} />
    </Routes>
  );
};
export default NotAuthenRouters;
