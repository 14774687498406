/* eslint-disable */
import { Route, Routes, Navigate } from 'react-router-dom';
import routes from 'router/routes';
import MainLayout from '../components/layouts/mainLayout';
import { BasicLayout, UpdateProfile, NotFoundPage, DashboardPage } from 'pages';
import { useSelector } from 'react-redux';
import { orgSelector, userSelector } from 'redux/selectors';
import { routePaths } from 'utils/constants';
import { Helmet } from 'react-helmet';
import { checkPermission } from 'utils/utils';
const { DASHBOARD_PAGE, UPDATE_PROFILE } = routePaths;

const Layout = (props: any) => (props.layout === 'MainLayout' ? <MainLayout {...props} /> : <BasicLayout {...props} />);

const ElementLayout = (props: any) => (
  <Layout {...props}>
    <Helmet>
      <title>{props.title ? props.title : 'HEDGE Health'}</title>
      <meta name="description" content={'Description'} />
    </Helmet>
    {props.component ? <props.component type={props.type} permissions={props.permissions} /> : <></>}
  </Layout>
);

const AuthenRouters = () => {
  const { userInfo } = useSelector(userSelector);
  const { organizationId } = useSelector(orgSelector);
  const isUpdate = !!userInfo?.updatedProfile;
  return (
    <Routes>
      <Route path={DASHBOARD_PAGE} element={!isUpdate ? <Navigate to={UPDATE_PROFILE} /> : <DashboardPage />} key={`route-dashboard`} />
      <Route path={UPDATE_PROFILE} element={isUpdate ? <Navigate to={DASHBOARD_PAGE} /> : <UpdateProfile />} key={`route-update-profile`} />
      {routes.map((route, index) => {
        const checkPerm = checkPermission(userInfo, route.type, route.permissions, organizationId);
        const element = checkPerm ? <ElementLayout {...route} /> : <Navigate to={DASHBOARD_PAGE} />;
        return <Route path={route.path} element={element} key={`route-${index + 1}`} />;
      })}
      <Route path="*" element={<NotFoundPage />} key={`route-not-found`} />
    </Routes>
  );
};
export default AuthenRouters;
