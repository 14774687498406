import { BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/selectors';
import { isEmpty } from 'lodash';
import { getToken } from 'oauth';
import useUser from 'hooks/useUser';
import { useQuery } from 'react-query';
import SpinnerComponent from 'components/spinner';
import NotAuthenRouters from './NotAuthenRouters';
import AuthenRouters from './AuthenRouters';

const Router = () => {
  const { fetchCurrentUser } = useUser();
  const { userInfo } = useSelector(userSelector);

  const getCurrentUser = async () => {
    const token: any = getToken();
    if (token) await fetchCurrentUser(token);
  };
  const { isLoading } = useQuery({ queryKey: 'getCurrentUser', queryFn: getCurrentUser, staleTime: Infinity });

  if (isLoading) return <SpinnerComponent />;
  return <BrowserRouter>{isEmpty(userInfo) ? <NotAuthenRouters /> : <AuthenRouters />}</BrowserRouter>;
};

export default Router;
