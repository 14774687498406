import base from './baseApi';
import { queryFilter } from 'utils/proptypes';
import { createSearchParams } from 'react-router-dom';
import { omitBy } from 'lodash';

const { urls, methods, execute } = base();

const getConversations = (queryFilter: queryFilter) => {
  const initParams:any = omitBy(queryFilter, v => v === '')
  const params = createSearchParams(initParams);
  const method = methods.GET;
  let url = `${urls.conversation.conversations}?${params}`;
  const response = execute(method, url);
  return response;
};

const createConversations = (payload: any) => {
  const method = methods.POST;
  let url = `${urls.conversation.conversations}`;
  const response = execute(method, url, payload);
  return response;
};

const getMessages = (queryFilter: any) => {
  const initParams:any = omitBy(queryFilter, v => v === '')
  const params = createSearchParams(initParams);
  const method = methods.GET;
  let url = `${urls.message.messages}?${params}`;
  const response = execute(method, url);
  return response;
};

const createMessage = (payload: any) => {
  const method = methods.POST;
  let url = `${urls.message.messages}`;
  const response = execute(method, url, payload);
  return response;
};


export { getConversations, createConversations, getMessages, createMessage };
