// eslint-disable-next-line
import base from './baseApi';
import { omitBy } from 'lodash';
import { createSearchParams } from 'react-router-dom';

const { urls, methods, execute } = base();
const getProgramTemplates = (queryFilter: any) => {
  const method = methods.GET;
  const initParams: any = omitBy(queryFilter, v => v === '');
  const params = createSearchParams(initParams);

  const url = `${urls.programTemplates.programTemplates}?${params.toString()}`;
  const response = execute(method, url);

  return response;
};

const createProgramTemplate = (payload: any) => {
  const method = methods.POST;
  const url = `${urls.programTemplates.programTemplates}`;
  const response = execute(method, url, payload);

  return response;
};

const updateProgramTemplate = (programTemplateId: string, payload: any) => {
  const method = methods.PUT;
  const url = `${urls.programTemplates.programTemplates}/${programTemplateId}`;
  const response = execute(method, url, payload);

  return response;
};

const getProgramTemplateById = (programTemplateId: any) => {
  const method = methods.GET;

  const url = `${urls.programTemplates.programTemplates}/${programTemplateId}`;
  const response = execute(method, url);

  return response;
}

const deleteProgramTemplate = (id: any) => {
  const method = methods.DELETE;
  const url = `${urls.programTemplates.programTemplates}/${id}`;
  const response = execute(method, url);

  return response;
};

const updateProgramTemplateExercise = (exerciseId: string, payload: any) => {
  const method = methods.PUT;
  const url = `${urls.programTemplates.programTemplatesExercise}/${exerciseId}`;
  const response = execute(method, url, payload);

  return response;
};

const deleteProgramTemplateExercise = (exerciseId: string) => {
  const method = methods.DELETE;
  const url = `${urls.programTemplates.programTemplatesExercise}/${exerciseId}`;
  const response = execute(method, url);

  return response;
};


export {
  getProgramTemplates,
  createProgramTemplate,
  getProgramTemplateById,
  updateProgramTemplate,
  deleteProgramTemplate,
  updateProgramTemplateExercise,
  deleteProgramTemplateExercise
};
