// eslint-disable-next-line
import base from './baseApi';
import { omitBy } from 'lodash';
import { createSearchParams } from 'react-router-dom';

const { urls, methods, execute } = base();
const getExerciseTemplates = (queryFilter: any) => {
  const method = methods.GET;
  const initParams: any = omitBy(queryFilter, v => v === '');
  const params = createSearchParams(initParams);

  const url = `${urls.exerciseTemplate.exerciseTemplate}/?${params.toString()}`;
  const response = execute(method, url);

  return response;
};

const getExerciseTemplateById = (exerciseTemplateId: any) => {
  const method = methods.GET;

  const url = `${urls.exerciseTemplate.exerciseTemplate}/${exerciseTemplateId}`;
  const response = execute(method, url);

  return response;
}


const createExerciseTemplate = (payload: any) => {
  const method = methods.POST;
  const data: any = omitBy(payload, v => v === '');

  const url = `${urls.exerciseTemplates.exerciseTemplates}`;
  const response = execute(method, url, data);

  return response;
};
const deleteExerciseTemplate = (id: any) => {
  const method = methods.DELETE;
  const url = `${urls.exerciseTemplates.exerciseTemplates}/${id}`;
  const response = execute(method, url);

  return response;
};
const updateExerciseTemplate = (exerciseTemplateId: string, payload: any) => {
  const method = methods.PUT;
  const url = `${urls.exerciseTemplates.exerciseTemplates}/${exerciseTemplateId}`;
  const response = execute(method, url, payload);

  return response;
};
export {
  getExerciseTemplates,
  createExerciseTemplate,
  deleteExerciseTemplate,
  getExerciseTemplateById,
  updateExerciseTemplate
};
