// eslint-disable-next-line
import base from './baseApi';
import { omitBy } from 'lodash';
import { createSearchParams } from 'react-router-dom';

const { urls, methods, execute } = base();
const getPatientPrograms = (queryFilter: any) => {
  const method = methods.GET;
  const initParams: any = omitBy(queryFilter, v => v === '');
  const params = createSearchParams(initParams);

  const url = `${urls.programs.programs}?${params.toString()}`;
  const response = execute(method, url);

  return response;
};
const createProgram = (payload: any) => {
  const method = methods.POST;
  const url = `${urls.programs.programs}`;
  const response = execute(method, url, payload);

  return response;
};

const updateProgram = (programId: string, payload: any) => {
  const method = methods.PUT;
  const url = `${urls.programs.programs}/${programId}`;
  const response = execute(method, url, payload);

  return response;
};


const getProgramById = (programId: any, includeArchived = true) => {
  const method = methods.GET;

  const url = `${urls.programs.programs}/${programId}?includeArchived=${includeArchived}`;
  const response = execute(method, url);

  return response;
}

const changeProgramStatus = ({ id, payload }: any) => {
  const method = methods.PATCH;
  const url = `${urls.programs.programs}/change-status/${id}`;
  const response = execute(method, url, payload);

  return response;
};


const deleteProgram = (id: any) => {
  const method = methods.DELETE;
  const url = `${urls.programs.programs}/${id}`;
  const response = execute(method, url);

  return response;
};

export {
  getPatientPrograms,
  changeProgramStatus,
  deleteProgram,
  createProgram,
  getProgramById,
  updateProgram
};
