import dayjs from 'dayjs';
import { Slide, toast, TypeOptions } from 'react-toastify';
import { concat, find, flattenDeep, intersection, isArray, isEmpty, map, uniq } from 'lodash';
import * as XLSX from 'xlsx';
import { codeVerifier, ORG_COOKIE_KEY, pageType, permissionKeys, routePaths } from './constants';
import { getCookie } from './cookie';
import { userType } from './proptypes';
import moment from 'moment';
import copy from 'copy-to-clipboard';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile } from '@ffmpeg/util';

export const formatDate = (date: string) => {
  const datejs = dayjs(date);
  const $d = datejs.toDate();
  const $D = datejs.date();
  const $M = datejs.month();
  const $y = datejs.year();

  const dateTime = $d.toString().split(' ')[4];

  const splitTime = dateTime.split(':');

  return `${$D > 9 ? $D : '0' + $D}/${$M + 1 > 9 ? $M + 1 : '0' + ($M + 1)}/${$y} ${
    parseInt(splitTime[0]) > 12 ? parseInt(splitTime[0]) - 12 : splitTime[0]
  }:${splitTime[1]}${parseInt(splitTime[0]) > 12 ? 'pm' : 'am'}`;
};

export const checkLowerUpper = (value: string) => {
  return /(?=.*[A-Z])(?=.*[a-z]).*$/.test(value);
};

export const checkNumberDymbol = (value: string) => {
  return /(?=.*\d)(?=.*\W+).*$/.test(value);
};

export const checkLeast8Char = (value: string) => {
  return value.length >= 8;
};

export const checkNoSpaces = (value: string) => {
  return /^\S+$/.test(value);
};

export const validatiePassword = (value: string) => {
  return checkLowerUpper(value) && checkNumberDymbol(value) && checkLeast8Char(value) && checkNoSpaces(value);
};

export const messageErrors = (error: any, translation: any) => {
  if ([500, 404].includes(error?.response?.status)) {
    return translation('generalErrors');
  } else {
    const message = error?.response?.data?.errors[0]?.detail;
    return (isArray(message) ? translation(message[0]) : translation(message)) || translation('generalErrors');
  }
};

export const exportToFile = (exportedData: any, fileName: string) => {
  const wb = XLSX.utils.json_to_sheet(exportedData);
  const wbout = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wbout, wb);
  XLSX.writeFile(wbout, fileName);
};

export const checkPermission = (user: any, type: any, permNeed: any = [], organizationId = getCookie(ORG_COOKIE_KEY), exception?: any): any => {
  const permissionsSite = uniq(flattenDeep(map(user?.roles, (r: any) => r?.permissions)));
  const permissionsOrg = find(user?.organizations, o => o.id === organizationId)?.role?.permissions || [];

  if (permissionsSite.includes(permissionKeys.PLATFORM_ADMIN)) return true;
  if (exception) return false;
  let permOfUser = concat(permissionsSite, permissionsOrg);
  if (type === pageType.ORGANIZATION) permOfUser = permissionsOrg;
  if (type === pageType.SITE) permOfUser = permissionsSite;
  return permOfUser && (isEmpty(permNeed) || !isEmpty(intersection(permOfUser, permNeed)));
};

export const checkRole = (user: any, roleNames: any[], organizationId = getCookie(ORG_COOKIE_KEY)): any => {
  const displayName = find(user?.organizations, o => o.id === organizationId)?.role?.displayName || [];
  return roleNames.includes(displayName);
};

export const getNavbarUrl = (user: any, type: string, organizationId = getCookie(ORG_COOKIE_KEY)) => {
  const permissionsSite = uniq(flattenDeep(map(user?.roles, (r: any) => r?.permissions)));
  const { READ_USER, WRITE_USER, PLATFORM_ADMIN, READ_ROLE, WRITE_ROLE, READ_ORG, WRITE_ORG, READ_AUTHEN, WRITE_AUTHEN } = permissionKeys;
  if (type === pageType.SITE) {
    if (!isEmpty(intersection(permissionsSite, [READ_USER, WRITE_USER, PLATFORM_ADMIN]))) return routePaths.ADMIN_USERS_PAGE;
    if (!isEmpty(intersection(permissionsSite, [READ_ROLE, WRITE_ROLE]))) return routePaths.ROLES_PAGE;
    if (!isEmpty(intersection(permissionsSite, [READ_ORG, WRITE_ORG]))) return routePaths.ORGANIZATION_PAGE;
    if (!isEmpty(intersection(permissionsSite, [READ_AUTHEN, WRITE_AUTHEN]))) return routePaths.MY_TOKENS;
  } else {
    const permissionsOrg = find(user?.organizations, o => o.id === organizationId)?.role?.permissions || [];
    if (!isEmpty(intersection(permissionsSite, [PLATFORM_ADMIN]))) return routePaths.ORG_OVERVIEW_PAGE;
    if (!isEmpty(intersection(permissionsOrg, [READ_USER, WRITE_USER]))) return routePaths.ORG_OVERVIEW_PAGE;
  }
  return routePaths.DASHBOARD_PAGE;
};

export const getAvatarColor = (item: userType) => {
  const colors = ['#FACA15', '#31C48D', '#16BDCA', '#6875F5', '#9061F9', '#E74694', '#FF5A1F', '#F05252'];
  const sumOfDateNumbers = moment(item?.created)
    .utc()
    .format('MM DD YYYY hh mm ss')
    .split('')
    .map(Number)
    .reduce((a, b) => {
      return a + b;
    }, 0);
  const pick = sumOfDateNumbers % colors.length;
  return colors[pick];
};

export const copyToClipboard = (value: string, notification: string) => {
  copy(value);
  toast.success(notification);
};

export const handleTrimString = (values: any) => {
  Object.keys(values).forEach(k => {
    if (typeof values[k] === 'string') {
      values[k] = values[k].trim();
    }
  });
  return values;
};

export const getYoutubeId = (url: string) => {
  // Regular expression to extract the YouTube video ID
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);

  if (match && match[7].length === 11) {
    return match[7];
  } else {
    return null;
  }
};
export const isVideoType = (type: string) => {
  return /^video\//.test(type);
};

export const isImageType = (type: string) => {
  return /^image\//.test(type);
};

export const getSecondsFromHHMMSS = (value: any) => {
  const [str1, str2, str3] = value.split(':');

  const val1 = Number(str1);
  const val2 = Number(str2);
  const val3 = Number(str3);

  if (!isNaN(val1) && isNaN(val2) && isNaN(val3)) {
    return val1;
  }

  if (!isNaN(val1) && !isNaN(val2) && isNaN(val3)) {
    return val1 * 60 + val2;
  }

  if (!isNaN(val1) && !isNaN(val2) && !isNaN(val3)) {
    return val1 * 60 * 60 + val2 * 60 + val3;
  }

  return 0;
};

export const toHHMMSS = (secs: any) => {
  const secNum = parseInt(secs.toString(), 10);
  const hours = Math.floor(secNum / 3600);
  const minutes = Math.floor(secNum / 60) % 60;
  const seconds = secNum % 60;

  return [hours, minutes, seconds]
    .map(val => (val < 10 ? `0${val}` : val))
    .filter((val, index) => val !== '00' || index > 0)
    .join(':');
  // .replace(/^0/, "");
};

export const isSafari = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.includes('safari');
};

export const compressVideo = async (file: any) => {
  try {
    const inputFile = file?.name;
    const outputFile = 'video.mp4';
    const ffmpeg = new FFmpeg();
    await ffmpeg.load();
    await ffmpeg.writeFile(inputFile, await fetchFile(file));
    await ffmpeg.exec(['-i', inputFile,'-vf','scale=-2:360','-c:v', 'libx264','-crf', '18', outputFile]);
    const fileData = await ffmpeg.readFile(outputFile);
    const data = new Uint8Array(fileData as ArrayBuffer);
    const blob = new Blob([data.buffer], { type: 'video/mp4' });
    return new File([blob], outputFile, { type: 'video/mp4' });
  }catch(error) {
   console.log('error', error);
    return file;
  }

};

export const verifyPassword = (value: any, t: any) => {
  const hasUpperCase = /[A-Z]/.test(value);
  const hasLowerCase = /[a-z]/.test(value);
  const hasNumber = /\d/.test(value);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);

  if (!hasUpperCase) {
    return t('InvaldPassword1');
  } else if (!hasLowerCase) {
    return t('InvaldPassword2');
  } else if (!hasNumber) {
    return t('InvaldPassword3');
  } else if (!hasSpecialChar) {
    return t('InvaldPassword4');
  }
  return true;
};
