const httpMethods = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete',
};

const roleName = {
  SITE_ADMIN: 'Site Admin',
  ORGANIZATION_ADMIN: 'Organization Admin',
  STANDARD_USER: 'Standard User',
  INTEGRATOR: 'Integrator',
  THERAPIST: 'Therapist',
  PATIENT: 'Patient'
};

const roleOptions = [
  { label: 'Site Admin', value: roleName.SITE_ADMIN },
  { label: 'Organization Admin', value: roleName.ORGANIZATION_ADMIN },
  { label: 'Standard User', value: roleName.STANDARD_USER },
];

const routePaths = {
  FILE_REVIEW_PAGE: '/review/:id',
  LOGIN_PAGE: '/login',
  SIGNUP_PAGE: '/signup',
  VERSION_PAGE: '/version',
  LOGOUT_PAGE: '/logout',
  FORGOT_PASSWORD: '/porgot-password',
  CHANGE_PASSWORD: '/change-password',
  NOT_FOUND_PAGE: '/not-found',
  CONTACT_SUPPORT: '/contact-support',
  PROFILE_PAGE: '/profile',
  UPDATE_PROFILE: '/update-profile',
  ORGANIZATION_PAGE: '/admin/organizations',
  ROLES_PAGE: '/admin/roles',
  ADMIN_USERS_PAGE: '/admin/users',
  ORG_USERS_PAGE: '/organization/users',
  ORG_OVERVIEW_PAGE: '/organization/overview',
  DASHBOARD_PAGE: '/',
  EMAIL_VERIFICATION: '/email-verification',
  SUBSCRIPTION: '/subscription',
  MY_TOKENS: '/my-tokens',
  MY_SUBSCRIPTIONS: '/my-subscription',
  STRIPE_PAYMENT: '/stripe-payment',
  ADMIN_USER_DETAIL: '/admin/users/:id',
  ORG_USER_DETAIL: '/organization/users/:id',
  ORG_THERAPISTS: '/organization/therapists',
  ORG_PATIENTS: '/organization/patients',
  THERAPIST_PATIENTS: '/therapist/patients',
  THERAPIST_PATIENTS_DETAIL: '/therapist/patients/:id',
  ORG_THERAPIST_DETAIL: '/organization/therapists/:id',
  ORG_PATIENT_DETAIL: '/organization/patients/:id',
  THERAPIST_MESSAGES: '/therapist/messages',
  THERAPIST_MESSAGE_DETAIL: '/therapist/messages/:id',
  THERAPIST_PROGRAM_TEMPLATES: '/therapist/program-templates',
  THERAPIST_EXERCISE_LIBRARY: '/therapist/exercise-library',
  PROGRAM_DETAIL: '/therapist/patients/:id/program-detail',
  PATIENT_MESSAGES: '/patient/messages',
  PATIENT_MESSAGE_DETAIL: '/patient/messages/:id',
  PATIENT_PROGRAM: '/patient/program',
  ADD_PROGRAM_TEMPLATE: '/therapist/program-templates/add-program-template',
  PROGRAM_TEMPLATE_DETAIL: '/therapist/program-templates/program-template-detail',
  PATIENT_DASHBOARD: '/patient/dashboard',
  PATIENT_PROGRAM_EXERCISES: '/patient/program/:programId',
  THERAPIST_EXERCISE_TEMPLATES: '/therapist/exercise-templates',
  ADD_EXERCISE_TEMPLATE: '/therapist/exercise-templates/add-exercise-template',

};

const status = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};

const apiStatus = {
  SUCCESS: 200,
  NO_CONTENT: 204,
};

const pageCount = 10;

const defaultpageCount = 10;

const exportFileName = {
  ORGANIZATION: 'ListOrganization.csv',
  USER: ' ListUser.csv',
  USER_ORGANIZATION: 'UserOrganization.csv',
  TOKENS: 'Tokens.csv',
  ROLE: 'Role.csv',
  MY_SUPSCRIPTIONS: 'MySupscriptions.csv',
};

const roles = ['admin', 'user', 'super-admin'];

const tokenKey = 'token';
const refreshTokenKey = 'refreshToken';

const searchMaxLength = 500;

const delayShowToast = 2000;

const acceptImageTypeFile = '.png, .jpeg, .jpg, .gif';

const BUTTON_COLOR = {
  BLUE: 'bg-blue-600 hover:bg-blue-700',
  RED: 'bg-red-500 hover:bg-red-800',
  GRAY: 'bg-gray-300 hover:bg-gray-500',
  OUTLINE: 'bg-white border border-gray-200 hover:bg-blue-100 hover:border-blue-600',
};

const page_default = 0;
const limit_default = 1000;

const INTERVAL = {
  MONTH: 'month',
  YEAR: 'year',
};

const SUBSCRIPTION_TYPE = {
  BASIC: 'Basic',
  STANDARD: 'Standard',
  ENTERPRISE: 'Enterprise',
};

const stripeSucceeded = 'succeeded';
const roleTypeOptions = [
  { label: 'Site', value: 'Site' },
  { label: 'Organization', value: 'Organization' },
];

const ORG_COOKIE_KEY = 'organization_id';
const DEVICE_ID = 'deviceId';

const IMAGE_TYPE_REGEX = /\.(jpg|jpeg|png|gif)$/i;

const permissionKeys = {
  PLATFORM_ADMIN: 'PLATFORM_ADMIN',
  READ_AUTHEN: 'READ_AUTHEN',
  READ_ORG: 'READ_ORG',
  READ_USER: 'READ_USER',
  READ_ROLE: 'READ_ROLE',
  READ_PLAN: 'READ_PLAN',
  READ_SUBSCRIPTION: 'READ_SUBSCRIPTION',
  READ_PROGRAM: 'READ_PROGRAM',
  WRITE_AUTHEN: 'WRITE_AUTHEN',
  WRITE_ORG: 'WRITE_ORG',
  WRITE_USER: 'WRITE_USER',
  WRITE_ROLE: 'WRITE_ROLE',
  WRITE_PLAN: 'WRITE_PLAN',
  WRITE_SUBSCRIPTION: 'WRITE_SUBSCRIPTION',
  WRITE_PROGRAM: 'WRITE_PROGRAM'
};

const permissionsSite = [
  permissionKeys.PLATFORM_ADMIN,
  permissionKeys.READ_ORG,
  permissionKeys.READ_USER,
  permissionKeys.READ_ROLE,
  permissionKeys.READ_AUTHEN,
  permissionKeys.WRITE_ORG,
  permissionKeys.WRITE_USER,
  permissionKeys.WRITE_ROLE,
  permissionKeys.WRITE_AUTHEN,
];
const permissionsOrg = [permissionKeys.PLATFORM_ADMIN, permissionKeys.READ_USER, permissionKeys.WRITE_USER];

const pageType = {
  SITE: 'Site',
  ORGANIZATION: 'Organization',
  THERAPIST: 'Therapist',
  PATIENT: 'Patient',
};

const sortByTypeType = {
  ASC: 'ASC',
  DESC: 'DESC',
};

const pageSizeOptions = [
  { value: 15, label: 15 },
  { value: 30, label: 30 },
  { value: 45, label: 45 },
  { value: 50, label: 50 },
  { value: 65, label: 65 },
];

const ProgramStatusOptions = [
  { value: 'Active', label: 'Active' },
  { value: 'Inactive', label: 'Inactive' },
  { value: 'Draft', label: 'Draft' },
]

const ProgramTypeOptions = [
  { value: 'Fitness', label: 'Fitness' },
  { value: 'Treatment', label: 'Treatment' },
]

const ProgramStatus = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  DRAFT: 'Draft',
}

const ProgramType = {
  TREATMENT: 'Treatment',
}

const codeVerifier = 'mhO1Z6HDCDEXcAqyXT3cLPEayfh3p2zoEfcnTMZds7g';

export {
  httpMethods,
  roleName,
  roleOptions,
  routePaths,
  status,
  roles,
  defaultpageCount,
  apiStatus,
  exportFileName,
  tokenKey,
  refreshTokenKey,
  searchMaxLength,
  delayShowToast,
  acceptImageTypeFile,
  BUTTON_COLOR,
  page_default,
  limit_default,
  INTERVAL,
  SUBSCRIPTION_TYPE,
  roleTypeOptions,
  ORG_COOKIE_KEY,
  stripeSucceeded,
  IMAGE_TYPE_REGEX,
  permissionKeys,
  pageType,
  permissionsSite,
  permissionsOrg,
  sortByTypeType,
  pageSizeOptions,
  pageCount,
  ProgramStatusOptions,
  ProgramTypeOptions,
  ProgramStatus,
  codeVerifier,
  ProgramType,
  DEVICE_ID
};
