import base from './baseApi';
const { methods, execute } = base();
const { POST, DELETE } = methods;

export const signin = (payload: any) => {
  return execute(POST, '/authentication/signin', payload);
};

export const signup = (payload: any) => {
  return execute(POST, '/authentication/signup', payload);
};

export const performLogout = () => {
  return execute(DELETE, '/authentication/performLogout');
};

export const generateVerificationCode = (payload: any) => {
  return execute(POST, '/authentication/generateVerificationCode', payload);
};

export const checkVerificationCode = (payload: any) => {
  return execute(POST, '/authentication/checkVerificationCode', payload);
};

export const resetPassword = (payload: any) => {
  return execute(POST, '/authentication/resetPassword', payload);
};