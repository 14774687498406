import { ORG_COOKIE_KEY, tokenKey, permissionKeys } from 'utils/constants';
import { getCurrentUser } from 'api/userApi';
import { orgAction, userAction } from '../redux/actions';
import { useDispatch } from 'react-redux';
import { deleteCookie } from 'utils/cookie';
import { flattenDeep, get, isEmpty, map, orderBy } from 'lodash';
import { setOrgCookie, getOrganizations } from 'api/organizationApi';
import { performLogout } from 'api/authenApi';

const useUser = () => {
  const dispatch = useDispatch();

  const fetchCurrentUser = async (idToken = '') => {
    try {
      const { data } = await getCurrentUser(idToken);
      const checkPlatformAdmin = flattenDeep(map(data.roles, r => r.permissions)).includes(permissionKeys.PLATFORM_ADMIN);
      if (checkPlatformAdmin) data.organizations = get(await getOrganizations(), 'data.entities', []);
      data.organizations = orderBy(data.organizations, 'created', 'desc');
      const organizations = data.organizations;
      const organizationDefault = localStorage.getItem(ORG_COOKIE_KEY);
      if (!organizations || isEmpty(organizations)) {
        deleteCookie(ORG_COOKIE_KEY);
      } else if (!organizationDefault || !organizations.find((o: any) => o.id === organizationDefault)) {
        await handleSetCookie(organizations[0].id);
      } else {
        await handleSetCookie(organizationDefault);
      }
      dispatch(userAction.setUserInfo(data));
    } catch (error: any) {
      if (error?.response?.status === 401) {
        deleteCookie(tokenKey);
        localStorage.removeItem(ORG_COOKIE_KEY);
      }
    }
  };

  const handleLogout = async () => {
    await performLogout();
    dispatch(userAction.cleanUserInfo());
    deleteCookie(tokenKey);
    localStorage.removeItem(ORG_COOKIE_KEY);
  };
  
  const handleSetCookie = async (organizationId: any) => {
    await setOrgCookie(organizationId);
    localStorage.setItem(ORG_COOKIE_KEY, organizationId);
    dispatch(orgAction.setOrgCookie(organizationId));
  };

  return {
    handleLogout,
    fetchCurrentUser,
    handleSetCookie,
  };
};

export default useUser;
