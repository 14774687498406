import {
  HiBadgeCheck,
  HiChatAlt2,
  HiChevronRight,
  HiFolder,
  HiHome,
  HiMail,
  HiMenuAlt2,
  HiOfficeBuilding,
  HiOutlineBadgeCheck,
  HiOutlineChatAlt2,
  HiOutlineFolder,
  HiOutlineHome,
  HiOutlineLogout,
  HiOutlineOfficeBuilding,
  HiOutlineUser,
  HiOutlineUsers,
  HiUsers,
  HiUserCircle,
  HiOutlineUserCircle,
  HiX,
  HiBookOpen,
  HiOutlineBookOpen,
} from 'react-icons/hi';
import { useLocation, useNavigate } from 'react-router-dom';
import { routePaths, permissionKeys, pageType, permissionsSite, permissionsOrg, roleName } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { conversationSelector, orgSelector, userSelector } from '../../redux/selectors';
import useUser from 'hooks/useUser';
import './styles.scss';
import { useState } from 'react';
import { checkPermission, checkRole, getNavbarUrl } from 'utils/utils';
import { userAction } from 'redux/actions';
import { find, isString, size } from 'lodash';
import Svg from 'components/svg';
import LabelUser from 'components/labelUser';
import Hightlight from 'components/hightlight';
import { useMutation } from 'react-query';
import SpinnerComponent from 'components/spinner';
const logo = require('../../assets/image/svg/logo.svg').default;
const TherapistIcon = () => <Svg name="therapist-icon" className="text-primary w-6 h-6" />;
const TherapistOutlineIcon = () => <Svg name="therapist-icon" className="text-on-primary-container w-6 h-6" />;

const SidebarComponent = (props?: any) => {
  const { READ_USER, WRITE_USER, READ_ORG, WRITE_ORG } = permissionKeys;
  const { READ_ROLE, WRITE_ROLE } = permissionKeys;
  const { isDashboard, type } = props || {};
  const location = useLocation();
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { organizationId } = useSelector(orgSelector);
  const navigate = useNavigate();
  const { userInfo } = useSelector(userSelector);
  const { handleLogout } = useUser();
  const [isShowMenu, setIsShowMenu] = useState(false);
  const { conversations } = useSelector(conversationSelector);
  const url = location.pathname;
  const checkTherapistRole = checkRole(userInfo, [roleName.THERAPIST, roleName.ORGANIZATION_ADMIN], organizationId);
  const checkPatientRole = checkRole(userInfo, [roleName.PATIENT], organizationId);
  const checkOrgAdminRole = checkRole(userInfo, [roleName.ORGANIZATION_ADMIN], organizationId);
  const checkAdminRole = userInfo?.roles.find((role: any) => role.displayName === roleName.SITE_ADMIN);
  const muHandleLogout = useMutation('handleLogout', { mutationFn: handleLogout });

  const checkActivePage = (page: string | string[]): any => {
    if (page === '/') {
      return url === '/';
    } else if (isString(page)) {
      return url.indexOf(page) === 0;
    } else {
      return !!find(page, (p: any) => url.startsWith(p));
    }
  };

  const toggleMenu = () => {
    setIsShowMenu(isShowMenu => !isShowMenu);
  };

  const handleOpenModalProfile = () => {
    const isOpenModal: any = true;
    dispatch(userAction.setOpenProfileModal(isOpenModal));
  };

  const renderSidebarItem = (isSubSidebar: any, route: string, IconActive: any, IconInactive: any, label: any) => {
    return (
      <li
        className={` ${isSubSidebar ? '!m-0' : '!mb-1'} cursor-pointer rounded-md ${
          !isSubSidebar && checkActivePage(route) && 'bg-inverse-on-surface bg-tertiaryDark'
        }`}
      >
        <button className="flex items-center py-2 rounded-sm w-full" onClick={() => (isSubSidebar ? nav(route) : navigate(route))}>
          {!isSubSidebar && <div className={`h-5 w-1 rounded-r-sm ${checkActivePage(route) && 'bg-primary'}`} />}
          <div className="ml-2.5 mr-3">
            {isSubSidebar && <HiChevronRight className={`w-5 h-5 ${checkActivePage(route) && 'text-white'}`} />}
            {!isSubSidebar && checkActivePage(route) && <IconActive className="w-6 h-6 text-white" />}
            {!isSubSidebar && !checkActivePage(route) && <IconInactive className="w-6 h-6 text-on-primary-container" />}
          </div>
          <p className={`text-sm block sm:hidden lg:block ${checkActivePage(route) ? 'text-white' : 'text-on-primary-container'}`}>{t(label)}</p>
        </button>
      </li>
    );
  };

  const getMenuSite = (isSubSidebar = false): any => {
    return (
      <div className={`${isSubSidebar && 'pl-3'}`}>
        <ul>
          {checkPermission(userInfo, pageType.SITE, [READ_ORG, WRITE_ORG], organizationId) &&
            renderSidebarItem(isSubSidebar, routePaths.ORGANIZATION_PAGE, HiOfficeBuilding, HiOutlineOfficeBuilding, 'sidebar.organizations')}
          {checkPermission(userInfo, pageType.SITE, [READ_USER, WRITE_USER], organizationId) &&
            renderSidebarItem(isSubSidebar, routePaths.ADMIN_USERS_PAGE, HiUsers, HiOutlineUsers, 'sidebar.userManagement')}
          {checkPermission(userInfo, pageType.SITE, [READ_ROLE, WRITE_ROLE], organizationId) &&
            renderSidebarItem(isSubSidebar, routePaths.ROLES_PAGE, HiBadgeCheck, HiOutlineBadgeCheck, 'sidebar.roles')}
        </ul>
      </div>
    );
  };

  const getMenuOrg = (isSubSidebar = false): any => {
    const checkOrg = checkPermission(userInfo, pageType.ORGANIZATION, [READ_USER, WRITE_USER], organizationId);
    if (!checkOrg) return;
    return (
      <div className={`${isSubSidebar && 'pl-3'}`}>
        <ul>
          {renderSidebarItem(isSubSidebar, routePaths.ORG_OVERVIEW_PAGE, HiOfficeBuilding, HiOutlineOfficeBuilding, 'sidebar.overview')}
          {checkPermission(userInfo, pageType.ORGANIZATION, [READ_ORG, WRITE_ORG], organizationId) &&
            renderSidebarItem(isSubSidebar, routePaths.ORG_THERAPISTS, TherapistIcon, TherapistOutlineIcon, 'sidebar.therapists')}
          {checkPermission(userInfo, pageType.ORGANIZATION, [READ_ORG, WRITE_ORG], organizationId) &&
            renderSidebarItem(isSubSidebar, routePaths.ORG_PATIENTS, HiUsers, HiOutlineUsers, 'sidebar.patients')}
        </ul>
      </div>
    );
  };

  const getMenuTherapist = (isSubSidebar = false): any => {
    if (!checkTherapistRole) return;
    return (
      <div className={`${isSubSidebar && 'pl-3'}`}>
        <ul>{renderSidebarItem(isSubSidebar, routePaths.THERAPIST_PATIENTS, HiUsers, HiOutlineUsers, 'patients')}</ul>
        <ul>{renderSidebarItem(isSubSidebar, routePaths.THERAPIST_MESSAGES, HiChatAlt2, HiOutlineChatAlt2, 'messages')}</ul>
        <ul>{renderSidebarItem(isSubSidebar, routePaths.THERAPIST_PROGRAM_TEMPLATES, HiFolder, HiOutlineFolder, 'programTemplates')}</ul>
        <ul>{renderSidebarItem(isSubSidebar, routePaths.THERAPIST_EXERCISE_TEMPLATES, HiBookOpen, HiOutlineBookOpen, 'exerciseLibrary')}</ul>
      </div>
    );
  };

  const getMenuPatient = (isSubSidebar = false): any => {
    if (!checkPatientRole) return;
    return (
      <div className={`${isSubSidebar && 'pl-3'}`}>
        <ul>{renderSidebarItem(isSubSidebar, routePaths.PATIENT_DASHBOARD, HiHome, HiOutlineHome, 'sidebar.dashboard')}</ul>
        <ul>{renderSidebarItem(isSubSidebar, routePaths.PATIENT_MESSAGES, HiChatAlt2, HiOutlineChatAlt2, 'messages')}</ul>
        <ul>{renderSidebarItem(isSubSidebar, routePaths.PATIENT_PROGRAM, HiFolder, HiOutlineFolder, 'sidebar.program')}</ul>
      </div>
    );
  };

  const nav = (path: string) => {
    toggleMenu();
    navigate(path);
  };

  const countHighlightMessage = () =>
    size(conversations?.filter((convers: any) => convers.messages.find((mess: any) => !mess.isView && mess.userId !== userInfo.id)));

  return (
    <>
      {/* for desktop */}
      <div className="sidebar fixed h-screen flex flex-col justify-between bg-tertiary text-white overflow-y-hidden border border-gray-200 shadow-md dark:border-gray-700 dark:bg-gray-800 text-left">
        <nav className="lg:w-52 w-full pt-5 pr-5">
          {!isDashboard && type === pageType.SITE && getMenuSite()}
          {!isDashboard && type === pageType.ORGANIZATION && getMenuOrg()}
          {type === pageType.THERAPIST && getMenuTherapist()}
          {(isDashboard || type === pageType.PATIENT) && getMenuPatient()}
        </nav>
      </div>

      {/*  for mobile */}
      <div className="flex sm:hidden nav-menu-mobile bg-tertiary">
        <button className="flex items-center" onClick={() => navigate(routePaths.DASHBOARD_PAGE)}>
          <img alt="MileMarker logo" className="mr-3 h-9 sm:h-9" src={logo} />
        </button>
        <div className="flex items-center">
          {type === pageType.THERAPIST && !url.includes(routePaths.THERAPIST_MESSAGES) && (
            <button className="mr-4 p-1 relative" onClick={() => navigate(routePaths.THERAPIST_MESSAGES)}>
              {!!countHighlightMessage() && <Hightlight count={countHighlightMessage()} className="absolute right-0 top-0" />}
              <HiMail className="text-white w-7 h-7" />
            </button>
          )}
          {type === pageType.PATIENT && !url.includes(routePaths.PATIENT_MESSAGES) && (
            <button className="mr-4 p-1 relative" onClick={() => navigate(routePaths.PATIENT_MESSAGES)}>
              {!!countHighlightMessage() && <Hightlight count={countHighlightMessage()} className="absolute right-0 top-0" />}
              <HiMail className="text-white w-7 h-7" />
            </button>
          )}
          <button className="inline-flex items-center p-2" onClick={toggleMenu}>
            <HiMenuAlt2 className="h-7 w-7 text-white" />
          </button>
        </div>
      </div>

      {/* menu for mobile */}
      <div className={`fixed z-10 w-full h-full ${!isShowMenu && 'hidden'}`}>
        <nav className="p-2.5 bg-[#FFFBFF] h-full w-full">
          <div className="flex justify-end">
            <button className="inline-flex items-center p-3" onClick={toggleMenu}>
              <HiX className="h-8 w-8 text-gray-900" />
            </button>
          </div>
          <div className="px-5 text-on-primary-container text-sm">
            <div className="flex px-2 pb-3.5 items-center">
              <LabelUser item={userInfo} theme={{ name: 'text-gray-900', email: 'text-gray-500' }} isCoppy={false} />
            </div>
            <div className="flex sm:hidden border border-hover-10 border-t" />
            <ul>
              <li className="!m-0">
                <button className="flex items-center py-3.5 cursor-pointer" onClick={() => handleOpenModalProfile()}>
                  {checkActivePage(routePaths.PROFILE_PAGE) ? <HiUserCircle className="w-6 h-6" /> : <HiOutlineUserCircle className="w-6 h-6" />}
                  <p className="ml-2.5">{t('sidebar.profile')}</p>
                </button>
                <div className="flex sm:hidden border border-hover-10 border-t" />
              </li>
              {checkTherapistRole && (
                <li className="!m-0">
                  <button className="flex items-center py-3.5 cursor-pointer" onClick={() => nav('/')}>
                    <HiOutlineUser className="w-6 h-6" />
                    <p className="ml-2.5">{t('sidebar.therapist')}</p>
                  </button>
                  {(isDashboard || type === pageType.THERAPIST) && <div className="mb-2">{getMenuTherapist(true)}</div>}
                  <div className="flex sm:hidden border border-hover-10 border-t" />
                </li>
              )}
              {checkPatientRole && (
                <li className="!m-0">
                  <button className="flex items-center py-3.5 cursor-pointer" onClick={() => nav('/')}>
                    <HiOutlineUser className="w-6 h-6" />
                    <p className="ml-2.5">{t('sidebar.patient')}</p>
                  </button>
                  {(isDashboard || type === pageType.PATIENT) && <div className="mb-2">{getMenuPatient(true)}</div>}
                  <div className="flex sm:hidden border border-hover-10 border-t" />
                </li>
              )}
              {checkPermission(userInfo, pageType.SITE, permissionsSite, organizationId) && checkAdminRole && (
                <li className="!m-0">
                  <button className="flex items-center py-3.5 cursor-pointer" onClick={() => nav(getNavbarUrl(userInfo, pageType.SITE))}>
                    {checkActivePage('/admin') ? <HiUsers className="w-6 h-6" /> : <HiOutlineUsers className="w-6 h-6" />}
                    <p className="ml-2.5">{t('sidebar.admin')}</p>
                  </button>
                  {type === pageType.SITE && <div className="mb-2">{getMenuSite(true)}</div>}
                  <div className="flex sm:hidden border border-hover-10 border-t" />
                </li>
              )}
              {checkPermission(userInfo, pageType.ORGANIZATION, permissionsOrg, organizationId) && (checkAdminRole || checkOrgAdminRole) && (
                <li className="!m-0">
                  <button className="flex items-center py-3.5 cursor-pointer" onClick={() => nav(routePaths.ORG_OVERVIEW_PAGE)}>
                    {checkActivePage('/organization') ? <HiOfficeBuilding className="w-6 h-6" /> : <HiOutlineOfficeBuilding className="w-6 h-6" />}
                    <p className="ml-2.5">{t('sidebar.organizationAdmin')}</p>
                  </button>
                  {type === pageType.ORGANIZATION && <div className="mb-2">{getMenuOrg(true)}</div>}
                  <div className="flex sm:hidden border border-hover-10 border-t" />
                </li>
              )}
            </ul>
            <button onClick={() => muHandleLogout.mutate()} className="flex items-center py-3.5 cursor-pointer">
              <HiOutlineLogout className="h-6 w-6 text-red-500" />
              <span className="ml-2.5 text-red-500">{t('logout')}</span>
            </button>
          </div>
        </nav>
      </div>
      {muHandleLogout.isLoading && <SpinnerComponent />}
    </>
  );
};
export default SidebarComponent;
