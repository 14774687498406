/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';
import '../styles/styles.scss';
import FormUpdateProfile from 'components/modal/formUpdateProfile';
import { useNavigate } from 'react-router-dom';
import { routePaths } from 'utils/constants';
type updateProfileProps = {
  isNavigatedFromDashboard?: boolean,
};

const UpdateProfile = (props: updateProfileProps) => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center pt-24 mx-auto h-full bg-gray-50">
      <h1 className="text-lg">
        <strong>{t('userProfilePage.almostDone')}</strong>
      </h1>
      <p className="pb-10 pt-4 px-2 text-center	">{t('userProfilePage.addYourInfo')}</p>
      <FormUpdateProfile isNavigatedFromDashboard={true} redirectToDashboard={() => navigate(routePaths.DASHBOARD_PAGE)} />
    </div>
  );
};
export default UpdateProfile;
